import * as React from 'react';
import { Link } from 'gatsby';

import { Text, BlogImage, BlogLiterature } from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import routes from '../../../routes';

import readingAsLeisure from '../../../images/educational-corner/blog15/citanje-kao-razonoda.png';
import kidsThatRead from '../../../images/educational-corner/blog15/djeca-koja-citaju.png';
import learningWriting from '../../../images/educational-corner/blog15/ucenje-pisanja-dok-uciteljica-objasnjava.png';

const ReadingAndWriting = () => {
  return (
    <PageNew
      title="KOKOLINGO - Čitanje i pisanje"
      description="Čitanje i pisanje vještine su kojima trebamo ovladati da bismo postigli 
      akademski uspjeh i komunikaciju s okolinom. Osim toga, omogućuju nam i socijalno, 
      političko te ekonomsko uključivanje u društvo. Iz svega toga vidimo 
      koliko su nam čitanje i pisanje važni u svakodnevnom životu."
      headline="Čitanje i pisanje"
    >
      <Text as="p">
        Čitanje i pisanje vještine su kojima trebamo ovladati da bismo postigli{' '}
        <b>akademski uspjeh</b> i <b>komunikaciju s okolinom</b>. Osim toga,
        omogućuju nam i socijalno, političko te ekonomsko uključivanje u
        društvo. Iz svega toga vidimo koliko su nam čitanje i pisanje važni u
        svakodnevnom životu. Razvojem tehnologije svakim danom sve smo više
        okruženi novim sadržajima koje čitamo - blogovima, vijestima, reklamama,
        znanstvenim člancima itd. Na društvenim mrežama komentiramo različite
        sadržaje i izmjenjujemo velik broj poruka. Da bismo to sve mogli
        učiniti, moramo imati razvijene vještine čitanja i pisanja.
      </Text>
      <Text as="h2">Preduvjeti čitanja i pisanja</Text>
      <Text as="p">
        Polazak u školu važan je događaj u životu svakog djeteta. To je novo
        poglavlje na koje se dijete treba prilagoditi da bi se moglo nositi sa
        svakodnevnim školskim obvezama. Dosad se ono uglavnom{' '}
        <Link
          href={routes.GAME_AS_A_KEY_TO_CHILDS_GROWTH}
          title="Igra - ključ rasta i razvoja djece"
          rel="noopener noreferrer"
          target="_blank"
        >
          <b>igralo</b>
        </Link>
        , a sada veći dio dana mora provesti u školi i rješavati domaću zadaću.
        No da bi dijete ovladalo čitanjem i pisanjem, mora imati usvojene
        određene{' '}
        <Link
          href={routes.PREREQUISITES_OF_READING_AND_WRITING}
          title="Predvještine čitanja i pisanja"
          target="_blank"
          rel="noopener noreferrer"
          hrefLang="hr"
        >
          <b>predvještine</b>
        </Link>{' '}
        koje će mu olakšati taj zadatak.
      </Text>
      <Text as="h2">Čitanje</Text>
      <Text as="p">
        Čitanje najčešće shvaćamo kao vještinu koja nam je potrebna za učenje
        jer njome dolazimo do velikog broja informacija. Naravno, čitanje može
        biti i razonoda. U toj aktivnosti uživa velik broj ljudi, ali da bismo
        čitanje počeli doživljavati kao ugodnu aktivnost, trebamo razmišljati o
        čitanju kao o nečemu što <i>želimo</i>, a ne o nečemu što <i>moramo</i>.
      </Text>
      <BlogImage
        imgSrc={readingAsLeisure}
        imgAlt="Citanje kao razonoda"
        imgTitle="Citanje kao razonoda"
      />
      <Text as="p">
        Čitanje se sastoji od dva međusobno povezana procesa. To su{' '}
        <b>dekodiranje</b> i <b>razumijevanje</b>. Dekodiranje podrazumijeva da
        možemo tečno, odnosno točno i brzo nešto pročitati, a razumijevanje
        podrazumijeva da razumijemo sadržaj koji smo pročitali. Ako je osoba
        neuspješna u dekodiranju teksta, tada će razumijevanje pročitanog biti
        narušeno.
      </Text>
      <Text as="p">
        Da bi netko mogao čitati tečno i s razumijevanjem, treba imati uredne{' '}
        <b>jezične vještine</b> i <b>vizualno-prostornu orijentaciju</b>.
        Jezične vještine podrazumijevaju vladanje semantikom (rječničkim
        znanjima), sintaksom (pravilnim slaganjem riječi u rečenice),
        morfologijom (gramatičkim svojstvima jezika – primjerice, deklinacija
        ili konjugacija), pragmatikom (upotrebom jezika – primjerice,
        razumijevanje prenesenih značenja) i fonologijom (primjerice,
        razlikovanjem glasova nekog jezika). Vizualno-prostorna orijentacija
        podrazumijeva snalaženje u prostoru, na papiru i na tijelu. Na papiru se
        ona odnosi na to da čitamo slijeva nadesno, odozgo prema dolje, da
        uočavamo sličnosti i razlike između slova, da uočavamo gdje je početak,
        a gdje kraj rečenice itd.
      </Text>
      <Text as="p">
        Iako se čitanje može činiti kao vrlo jednostavna aktivnost, ona
        uključuje niz predvještina koje djeca moraju usvojiti da bi mogla brzo,
        točno i s razumijevanjem pročitati neki tekst.
      </Text>
      <Text as="h2">Pisanje</Text>
      <Text as="p">
        Pisanje je metoda razmjene informacija. Njome bilježimo jezične
        informacije dogovorenim znakovima (slovima i interpunkcijom). Za razliku
        od govora, kojim također prenosimo informacije, pisanje je (relativno)
        trajno, pohranjivo (na papiru ili u digitalnom obliku), mobilno te nam
        daje mogućnost provjere i vraćanja. Pisanje daje drukčije mogućnosti
        nego govor, ali se teže usvaja.{' '}
        <b>
          Govor se usvaja spontano, a pisanje zahtijeva svjesni napor i
          intenzivno učenje.
        </b>
      </Text>
      <BlogImage
        imgSrc={learningWriting}
        imgAlt="Ucenje pisanja dok uciteljica objasnjava"
        imgTitle="Ucenje pisanja"
      />
      <Text as="p">
        Da bi osoba mogla pisati, mora imati određenu razinu motorne kontrole i
        fine koordinacije pokreta u prostoru. Tijekom pisanja dijete bi trebalo
        usvojiti pravilan hvat olovke - između palca, kažiprsta i srednjeg
        prsta, a prstenjak i mali prst odmaraju se na stolu.
      </Text>
      <Text as="p">
        Smislen sadržaj, koji je tečno napisan, zahtijeva usvojene{' '}
        <b>jezične vještine</b>, <b>vizualno-prostornu orijentaciju</b> i{' '}
        <b>grafomotoričke vještine</b>. U pisanju je bitno i planiranje, odnosno
        način organizacije teksta u smislene cjeline. Tijekom pisanja koristit
        ćemo se informacijama iz dugoročnog pamćenja, a u kratkoročno pamćenje
        pohranjivat ćemo trenutačne informacije, prilagođavat ćemo temu
        čitatelju, primjerice učiteljici, a sve napisano morat ćemo i
        provjeriti.
      </Text>
      <Text as="p">
        Pisanje, kao i čitanje, vrlo je složena vještina koja se, za razliku od
        govora koji se usvaja spontano, mora eksplicitno poučavati.
      </Text>
      <Text as="h2">Specifične teškoće učenja</Text>
      <Text as="p">
        <b>Disleksija</b> je jedna od specifičnih teškoća u učenju. To je
        jezično utemeljen poremećaj koji obilježavaju teškoće u dekodiranju
        riječi. One su neočekivane s obzirom na dob i ostale kognitivne i
        akademske sposobnosti te nisu rezultat općih razvojnih ili senzoričkih
        teškoća. Disleksija znatno utječe na akademsko postignuće i na
        svakodnevne zadaće koje zahtijevaju sposobnost čitanja. Čitanje je
        popraćeno iskrivljavanjem riječi, zamjenama, ispuštanjima ili
        dodavanjima glasova, sporošću i pogreškama u razumijevanju te osjećajem
        umora u situacijama čitanja. Sažeto, osoba koja ima disleksiju ne čita
        brzo i točno, a to dovodi do teškoća u razumijevanju pročitanog i
        sukladno tome do smanjenja akademskog postignuća.
      </Text>
      <Text as="p">
        Uz disleksiju se često vežu diskalkulija i disgrafija.{' '}
        <b>Diskalkuliju</b> obilježavaju problemi u usvajanju temeljnih
        aritmetičkih vještina (zbrajanje, oduzimanje, množenje i dijeljenje),
        obradi brojevnih veličina (primjerice, što je veće, a što manje) i
        teškoće u učenju matematičkih koncepata i procedura. <b>Disgrafija</b>{' '}
        je specifična teškoća koja se veže uz pisanje. Očituje se u lošem
        rukopisu, problemima oblikovanja misli u pisanoj formi, zrcaljenju
        tijekom pisanja (primjerice, slovo S piše naopako), ispuštanjima riječi
        i pogreškama u pisanju riječi (ispuštanje, dodavanje ili pogrešno
        pisanje slova). Kao i disleksija, diskalkulija i disgrafija spadaju u
        specifične teškoće učenja koje su neočekivane za dob i nisu posljedica
        neodgovarajućeg poučavanja ili intelektualnih teškoća.
      </Text>
      <Text as="p">
        Specifične teškoće učenja - disleksija, disgrafija i diskalkulija, mogu
        se dijagnosticirati tek nakon početka formalnog obrazovanja, odnosno
        nakon polaska u osnovnu školu. Uglavnom će se dijagnosticirati{' '}
        <b>u 3. razredu osnovne škole</b> jer se dotad ostvari dovoljna količina
        formalne pouke potrebna da se ovlada tim vještinama.
      </Text>
      <Text as="h2">
        Poticanje predvještina čitanja i pisanja te matematičkih sposobnosti u
        predškolskoj dobi
      </Text>
      <Text as="p">
        Djeca će polaskom u školu dobiti formalnu pouku koja će im omogućiti
        svladavanje čitanja, pisanja i matematičkih sposobnosti. U predškolskoj
        dobi možemo na razne načine poticati razvoj ovih vještina, važno je samo
        biti kreativan i zabavan:
        <ul>
          <li>
            zajedničko čitanje slikovnica uz djetetovo aktivno sudjelovanje -
            praćenje prstom, odgovaranje na pitanja, pripovijedanje itd.
          </li>
          <li>pjevanje pjesmica</li>
          <li>
            prepoznavanje riječi koje se rimuju i stvaranje rime - primjerice,
            roditelj kaže jednu riječ, a dijete mora smisliti drugu riječ koja
            se rimuje s njom
          </li>
          <li>
            spajanje slogova u riječi i rastavljanje riječi na slogove, uz
            pljeskanje
          </li>
          <li>
            izdvajanje prvog i zadnjeg glasa u riječima - primjerice, koristeći
            se igračkama navodimo dijete da ih prvo imenuje, a onda da kaže koji
            glas prvi čuje u njihovu imenu
          </li>
          <li>
            prepoznavanje slova na učestalim znakovima - primjerice, roditelji i
            dijete u šetnji stignu do Konzuma i onda roditelj kaže “Vidi, Konzum
            počinje slovom K. Što nam još sve počinje slovom K? Kuća ili miš?
            Soba ili kralj?”
          </li>
          <li>
            imenovanje riječi koje počinju određenim glasom - primjerice,
            roditelji se igraju da rade u trgovini, a dijete mora kupiti sve što
            počinje određenim glasom
          </li>
          <li>
            igre pamćenja - primjerice, koje se životinje skrivaju u kojoj
            kućici
          </li>
          <li>zajedničko crtanje i bojenje</li>
          <li>
            precrtavanje slova i geometrijskih likova - primjerice, dijete prema
            predlošku pokuša napisati imena članova obitelji
          </li>
          <li>
            povezivanje količine i broja - primjerice, izbroji koliko ima
            domaćih životinja u slikovnici ili izbroji koliko ima crvenih
            kockica
          </li>
          <li>
            orijentacija - nacrtaj psa na lijevoj/desnoj strani papira ili
            lijevom rukom dotakni svoj nos
          </li>
          <li>po uzorku povlačiti ravne i valovite linije</li>
          <li>
            zajedno škarama izrezivati slova i lijepiti ih u smislene riječi
          </li>
          <li>
            procjenjivati količinu bez brojenja - primjerice, “Čega ima više,
            jabuka ili banana?”
          </li>
          <li>
            uključiti dijete u svakodnevne aktivnosti - primjerice, kuhanje “Za
            ručak nam trebaju 2 krumpira, 3 mrkve i 4 rajčice”.
          </li>
        </ul>
      </Text>
      <BlogImage
        imgSrc={kidsThatRead}
        imgAlt="Djeca koja citaju"
        imgTitle="Djeca koja citaju"
      />
      <Text as="p">Krešimir Perša, mag. logoped.</Text>
      <BlogLiterature>
        <ul>
          <li>
            Lenček, M., Blaži, D. i Ivšac, J. (2007). Specifične teškoće učenja:
            osvrt na probleme u jeziku, čitanju i pisanju.{' '}
            <i>Magistra Iadertina</i>, 2(1), 107-121.
          </li>
          <li>
            Lenček, M. (2012). Procjena disleksije u hrvatskome: neke značajke
            čitanja i pisanja odraslih.{' '}
            <i>Hrvatska revija za rehabilitacijska istraživanja</i>, 48(1),
            11-26.
          </li>
        </ul>
      </BlogLiterature>
      <SectionBlog />
    </PageNew>
  );
};

export default ReadingAndWriting;
